import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { CookieUtil, isSafari } from '@axiom/ui';

import { EnvUtil } from '../utils/env-util';
import cookie from '../utils/universal-cookies';
import LoadingTreatment from '../components/LoadingTreatment/LoadingTreatment';
import BrowserCookie from '../utils/browser-cookies';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { ErrorText } from '../themes/components';
import { UserStore } from '../stores/user-store';
import { Page } from '../components/Page/Page';

const ContentWrapper = styled.div`
  margin: 5%;
  text-align: center;
`;

export const SSOPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const [ssoState, setSsoState] = useState({ loaded: false, error: false });

  const redirect = url => {
    setSsoState({
      loaded: true,
      error: false,
    });
    // Safari complains if a API is in flight and the page unloads, this will help mitigate.
    setTimeout(() => {
      window.location.href = url;
    }, 500);
  };

  useEffect(() => {
    if (query.get('user')) {
      const isBrowserSafari = isSafari();

      BrowserCookie('user', query.get('user'), {
        domain: `${EnvUtil.cookieDomain}`,
        SameSite: isBrowserSafari ? 'None' : 'Lax',
        secure: isBrowserSafari,
      });
    }

    UserStore.load().then(user => {
      const userToken = query.get('user')
        ? query.get('user')
        : cookie(document.cookie)?.user;

      if (!CookieUtil.isUserExpired(userToken)) {
        navigate('/', { replace: true });
      }

      const relayState = query.get('RelayState');
      const redirectTo = appUrl => {
        let relayTo = appUrl;

        if (relayState) {
          // add http protocol and hash, if needed and if possible, to relayState
          let protocol = '';
          let hash = '';
          if (!relayState.includes('http')) {
            // test-url.com => http(s)://test-url.com
            protocol = `${window.location.protocol}//`;
          }
          if (location.hash && !relayState.includes('#')) {
            // test-url.com => test-url.com#potato
            hash = location.hash;
          }
          relayTo = `${protocol}${relayState}${hash}`;
        }

        redirect(relayTo);
      };

      if (user?.roles) {
        if (user.roles.find(r => r.includes('ClientUser'))) {
          if (user?.hasAcceptedTermsAndConditions) {
            redirectTo(EnvUtil.afcRedirect);
          } else {
            navigate('/terms-of-use');
          }
        } else if (user.roles.find(r => r.includes('Envoy'))) {
          redirectTo(EnvUtil.envoyRedirect);
        } else if (user.roles.find(r => r.includes('LPUser'))) {
          if (user?.hasAcceptedTermsAndConditions) {
            redirectTo(EnvUtil.portalRedirect);
          } else {
            navigate('/terms-of-use');
          }
        } else {
          setSsoState({
            loaded: true,
            error: true,
          });
        }
      } else {
        setSsoState({
          loaded: true,
          error: true,
        });
      }
    });
  }, []);

  if (!ssoState.loaded) {
    return <LoadingTreatment />;
  }
  return (
    <Page title="Axiom Law - Signing On">
      <DefaultLayout>
        <ContentWrapper>
          {ssoState.hasError ? (
            <ErrorText>You are not authorized to go anywhere!</ErrorText>
          ) : (
            <span>You are being redirected...</span>
          )}
        </ContentWrapper>
      </DefaultLayout>
    </Page>
  );
};

SSOPage.propTypes = {};
